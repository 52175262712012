import React, { useState } from 'react'
import mainData from '../data/mainData'

import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

function Testimonial() {
    let [clientsComments, setComments] = useState(mainData?.clientsComments ?? []);

    const classMinHeight = {
        minHeight: '350px'
    }
    const classMinHeightName = {
        minHeight: '93px'
    }

    let options = {
        autoplay: true,
        center: true,
        dots: true,
        loop: true,
        smartSpeed: 1500,
        // margin:10,
        // nav:true,
        responsive: {
            0: {
                items: 1,
            },
            576: {
                items: 1,
            },
            768: {
                items: 2,
            },
            992: {
                items: 3,
            },
        },
    };

    return (
        <>
            {/* <!-- Testimonial Start --> */}
            <div id='testimonial' className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="section-title text-center position-relative pb-3 mb-4 mx-auto" style={{ maxWidth: '600px' }}>
                        <h5 className="fw-bold text-primary text-uppercase">Şərhlər</h5>
                        <h1 className="mb-0">Müştərilərimiz rəqəmsal xidmətlərimiz haqqında nə deyirlər</h1>
                    </div>
                    <div className='testimonial-carousel' data-wow-delay="0.6s">
                        <OwlCarousel className='owl-carousel wow fadeInUp' {...options}>
                        {/* <div className="testimonial-item bg-light my-4" style={classMinHeight}>
                            <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                                <img className="img-fluid rounded" src={require('../images/testimonial-4.jpg')} style={{ width: '60px', height: '60px' }} />
                                <div className="ps-4" style={classMinHeightName}>
                                    <h4 className="text-primary mb-1">Client Name</h4>
                                    <small className="text-uppercase">Profession</small>
                                </div>
                            </div>
                            <div className="pt-4 pb-5 px-5">
                                Dolor et eos labore, stet justo sed est sed. Diam sed sed dolor stet amet eirmod eos labore diam
                            </div>
                        </div> */}
                        {
                            clientsComments && clientsComments?.length > 0 ?
                                (
                                    clientsComments?.map((item, index) => (
                                        <div key={index} className="testimonial-item bg-light my-4" style={classMinHeight}>
                                            <div className="d-flex align-items-center border-bottom pt-5 pb-4 px-5">
                                                <img className="img-fluid rounded" src={require('../images/testimonial-4.jpg')} style={{ width: '60px', height: '60px' }} />
                                                <div className="ps-4" style={classMinHeightName}>
                                                    <h4 className="text-primary mb-1">{item?.name}</h4>
                                                    <small className="text-uppercase">{item?.profession}</small>
                                                </div>
                                            </div>
                                            <div className="pt-4 pb-5 px-5">
                                                {item?.comment}
                                            </div>
                                        </div>
                                    ))
                                ) : ""
                        }
                    </OwlCarousel>
                </div>
            </div>
        </div>
            {/* <!-- Testimonial End --> */ }
        </>
    )
}

export default Testimonial