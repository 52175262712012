import React, { useState, useEffect } from 'react'
import mainData from '../data/mainData'
import { Link } from 'react-router-dom';

// Пример отправки POST запроса:
async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return await response.json(); // parses JSON response into native JavaScript objects
}

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

function isEmpty(str) {
    return (!str || str.length === 0);
}

function Quote() {
    const {
        mobile
    } = { ...mainData };

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [token, setToken] = useState('');
    const [linke, setLink] = useState('http://tradeintellect.pro.az:8083');

    const onQuoteSubmit = (e) => {
        e.preventDefault();
        //alert(searchedText);
        const form = document.body.querySelector('#quoteForm');

        const formData = new FormData(form);

        for (const [key, value] of formData) {
            console.log(`${key}: ${value}\n`);
        }

        const _name = formData.get("name");
        //alert(_name);
        const _email = formData.get("email");
        //alert(_email);
        const _service = formData.get("service");
        //alert(_service);
        const _message = formData.get("message");
        //alert(_message);

        if (!validateEmail(_email)) {
            alert("E-mail is not correct");
            return;
        }
        if (isEmpty(_name)) {
            alert("Name is empty");
            return;
        }
        if (!_service || _service == 0) {
            alert("Service is not selected");
            return;
        }
        if (isEmpty(_message)) {
            alert("Message is empty");
            return;
        }

        const url = linke + '/appeals'; // URL для отправки запроса
        //alert(url);
        const datas = {
            name: _name,
            email: _email,
            service: _service,
            message: _message,
            // Другие данные, которые вы хотите отправить
        };

        postData(url, datas)
        .then(response => {
            //alert(JSON.stringify(response)); // JSON data parsed by `response.json()` call
            setResponseMessage(JSON.stringify(response));
        })
        .catch(error => setErrorMessage(error));

        if (JSON.parse(responseMessage)?.insertId > 0)
            alert("Qeydə alındı!");
    }

    return (
        <>
            {/* <!-- Quote Start --> */}
            <div id='quote' className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h5 className="fw-bold text-primary text-uppercase">TƏKLİF EDİN</h5>
                                <h1 className="mb-0">Zəhmət olmasa Bizimlə Əlaqə Saxlayın</h1>
                            </div>
                            <div className="row gx-3">
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                                    <h5 className="mb-4"><i className="fa fa-reply text-primary me-3"></i>24 saat ərzində cavablandırma</h5>
                                </div>
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                                    <h5 className="mb-4"><i className="fa fa-phone-alt text-primary me-3"></i>24 saat telefon dəstəyi</h5>
                                </div>
                            </div>
                            <p className="mb-4">Sizin biznesiniz bizimlə dayanmayacaq. Suallarınıza cavab, işinizə dəstək, bizimlə.</p>
                            <div className="d-flex align-items-center mt-2 wow zoomIn" data-wow-delay="0.6s">
                                <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: '60px', height: '60px' }}>
                                    <i className="fa fa-phone-alt text-white fs-5"></i>
                                </div>
                                <div className="ps-4">
                                    <h5 className="mb-2">İstənilən sual üçün zəng edin</h5>
                                    <a href={"tel:" + mobile?./*replaceAll('+', '').*/replaceAll(' ', '').replaceAll('-', '').replaceAll('/', '')} onClick={"ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"}>
                                        <h4 className="text-primary mb-0">{mobile}</h4>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-5">
                            <div className="bg-primary rounded h-100 d-flex align-items-center p-5 wow zoomIn" data-wow-delay="0.9s">
                                <form id='quoteForm' onSubmit={onQuoteSubmit}>
                                    <div className="row g-3">
                                        <div className="col-xl-12">
                                            <input type="text" className="form-control bg-light border-0" placeholder="Adınız" style={{ height: '55px' }} name='name' />
                                        </div>
                                        <div className="col-12">
                                            <input type="email" className="form-control bg-light border-0" placeholder="Elektron ünvanınız" style={{ height: '55px' }} name='email' />
                                        </div>
                                        <div className="col-12">
                                            <select className="form-select bg-light border-0" style={{ height: '55px' }} name='service'>
                                                <option value="0" selected>Xidmət növünü seçin</option>
                                                <option value="1">Service 1</option>
                                                <option value="2">Service 2</option>
                                                <option value="3">Service 3</option>
                                            </select>
                                        </div>
                                        <div className="col-12">
                                            <textarea className="form-control bg-light border-0" rows="3" placeholder="Mesajınız" name='message' maxlength='1500'></textarea>
                                        </div>
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-dark w-100 py-3">Göndər</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Quote End --> */}
        </>
    )
}

export default Quote