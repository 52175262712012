import mainDataEn from './mainDataEn.json';
import mainDataAz from './mainDataAz.json';
import mainDataRu from './mainDataRu.json';

const getMainData = (lang) => {
   switch (lang.toLowerCase()) {
    case "en":
      return mainDataEn;
    case "az":
      return mainDataAz;
    case "ru":
      return mainDataRu;
    default:
      break;
  }
  return;
};
const mainData = getMainData(localStorage.getItem("lang") == undefined || localStorage.getItem("lang") == null ? 'en' : localStorage.getItem("lang"));

export default mainData;