import React, { useEffect, useState } from "react";

import NavbarCarousel from "./components/NavbarCarousel";
import Spinner from "./components/Spinner";
import Topbar from "./components/Topbar";
import Login from "./pages/login";
import { Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";

import store from "./redux/store";
import Search from "./components/Search";
import Facts from "./components/Facts";
import About from "./components/About";
import Service from "./components/Service";
import Testimonial from "./components/Testimonial";
import Features from "./components/Features";
import PricingPlan from "./components/PricingPlan";
import Quote from "./components/Quote";
import Team from "./components/Team";
import Blog from "./components/Blog";
import Vendor from "./components/Vendor";
import Footer from "./components/Footer";
import BackTop from "./components/BackTop";
import YandexAdsWidget from "./components/YandexAdsWidget";

import { Helmet } from "react-helmet";
import YandexRTB from "./components/Ads/YandexRTB";

function App() {
  return (
    <>
      <Provider store={store}>
        <Routes>
          <Route path="*" element={
              <>
                {/* <Spinner /> */}
                <Topbar />
                <NavbarCarousel />
                <Search />
                <Facts />
                {/* <YandexAdsWidget /> */}
                <About />
                <Features />
                <Service />
                <PricingPlan />
                <Quote />
                {/* <YandexRTB /> */}
                <Testimonial />
                <Team />
                {/* <Blog /> */}
                <Vendor />
                <Footer /> 
                <BackTop />

                <Helmet>
                  <script src="lib/wow/wow.min.js" type="text/javascript" ></script>
                  <script src="lib/easing/easing.min.js" type="text/javascript" ></script>
                  <script src="lib/waypoints/waypoints.min.js" type="text/javascript" ></script>
                  <script src="lib/counterup/counterup.min.js" type="text/javascript" ></script>
                  {/* <script src="lib/owlcarousel/owl.carousel.min.js" type="text/javascript" ></script> */}
                  <script src="js/main.js"></script>
                </Helmet>
              </>
            }
          />
          <Route path="/login" element={<Login />} />
        </Routes>
      </Provider>
    </>
  );
}

export default App;
