import React from 'react';
import Slider from "react-slick";

// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Vendor() {
  var settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 6,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    initialSlide: 0,
    cssEase: "linear",
    swipeToSlide: true,
    // pauseOnHover: true,
    // centerPadding: "120px",
    // className: "center",
    // centerPadding: "120px",
    // rows: 2,
    // slidesPerRow: 2,
    // centerMode: true,
    // className: "slider variable-width",
    variableWidth: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div style={{ width: '98%' }}>
      <Slider {...settings}>
        <div className='vendor-image-div' style={{ width: '50px' }}>
          <img className='vendor-image' src={require('../images/nomre1corekzavodu.jpg')} alt="" />
        </div>
        <div className='vendor-image-div' style={{ width: '50px' }}>
          <img className='vendor-image' src={require('../images/technotel.png')} alt="" />
        </div>
        <div className='vendor-image-div'>
          <img className='vendor-image' src={require('../images/grandmart.png')} alt="" />
        </div>
        <div className='vendor-image-div'>
          <img className='vendor-image' src={require('../images/bolmart.png')} alt="" />
        </div>
        <div className='vendor-image-div'>
          <img className='vendor-image' src={require('../images/volay_red.png')} alt="" />
        </div>
        <div className='vendor-image-div'>
          <img className='vendor-image' src={require('../images/bizimmarket.png')} alt="" />
        </div>
        <div className='vendor-image-div'>
          <img className='vendor-image' src={require('../images/logoTiger3.png')} alt="" />
        </div>
        <div className='vendor-image-div'>
          <img className='vendor-image' src={require('../images/volay.png')} alt="" />
        </div>
        <div className='vendor-image-div'>
          <img className='vendor-image' src={require('../images/thermoline_logo.png')} alt="" />
        </div>
      </Slider>
    </div>
  )
}

export default Vendor