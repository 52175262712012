import React, { useState, useEffect } from 'react'
import mainData from '../data/mainData'

function Facts() {
    const {
        happyClients,
        projectsDone,
        winAwards,
        facts,
    } = { ...mainData };

    return (
        <>
            {/* <!-- Facts Start --> */}
            <div className="container-fluid facts py-5 pt-lg-0">
                <div className="container py-5 pt-lg-0">
                    <div className="row gx-0">
                        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.1s">
                            <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{ height: '150px' }}>
                                <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{ width: '70px', height: '70px' }}>
                                    <i className="fa fa-users text-primary fs-3"></i>
                                </div>
                                <div className="ps-4">
                                    <h5 className="text-white mb-0">{facts?.happyClients ?? ''}</h5>
                                    <h1 className="text-white mb-0" data-toggle="counter-up">{happyClients}</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.3s">
                            <div className="bg-light shadow d-flex align-items-center justify-content-center p-4" style={{ height: '150px' }}>
                                <div className="bg-primary d-flex align-items-center justify-content-center rounded mb-2" style={{ width: '70px', height: '70px' }}>
                                    <i className="fa fa-check text-white fs-3"></i>
                                </div>
                                <div className="ps-4">
                                    <h5 className="text-primary mb-0">{facts?.projectsDone ?? ''}</h5>
                                    <h1 className="mb-0" data-toggle="counter-up">{projectsDone}</h1>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 wow zoomIn" data-wow-delay="0.6s">
                            <div className="bg-primary shadow d-flex align-items-center justify-content-center p-4" style={{ height: '150px' }}>
                                <div className="bg-white d-flex align-items-center justify-content-center rounded mb-2" style={{ width: '70px', height: '70px' }}>
                                    <i className="fa fa-award text-primary fs-3"></i>
                                </div>
                                <div className="ps-4">
                                    <h5 className="text-white mb-0">{facts?.winAwards ?? ''}</h5>
                                    <h1 className="text-white mb-0" data-toggle="counter-up">{winAwards}</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Facts Start --> */}
        </>
    )
}

export default Facts