import React, { useState, useEffect } from 'react'
import mainData from '../data/mainData'

function Service() {
    const {
        mobile
    } = { ...mainData };

    return (
        <>
            {/* <!-- Service Start --> */}
            <div id='services' className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }}>
                        <h5 className="fw-bold text-primary text-uppercase">Bİzİm xİdmətlərİmİz</h5>
                        <h1 className="mb-0">Uğurlu Biznesiniz üçün Fərdi İT Həllər</h1>
                    </div>
                    <div className="row g-5">
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-shield-alt text-white fs-4"></i>
                                </div>
                                <h4 className="mb-3">Cyber Security</h4>
                                <p className="m-0"></p>
                                <a className="btn btn-lg btn-primary rounded" href="">
                                    <i className="bi bi-arrow-right fs-4"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-chart-pie text-white fs-4"></i>
                                </div>
                                <h4 className="mb-3">Data Analytics</h4>
                                <p className="m-0"></p>
                                <a className="btn btn-lg btn-primary rounded" href="">
                                    <i className="bi bi-arrow-right fs-4"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-code text-white fs-4"></i>
                                </div>
                                <h4 className="mb-3">Web Development</h4>
                                <p className="m-0"></p>
                                <a className="btn btn-lg btn-primary rounded" href="">
                                    <i className="bi bi-arrow-right fs-4"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.3s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fab fa-android text-white fs-4"></i>
                                </div>
                                <h4 className="mb-3">Apps Development</h4>
                                <p className="m-0"></p>
                                <a className="btn btn-lg btn-primary rounded" href="">
                                    <i className="bi bi-arrow-right fs-4"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.6s">
                            <div className="service-item bg-light rounded d-flex flex-column align-items-center justify-content-center text-center">
                                <div className="service-icon">
                                    <i className="fa fa-search text-white fs-4"></i>
                                </div>
                                <h4 className="mb-3">SEO Optimization</h4>
                                <p className="m-0"></p>
                                <a className="btn btn-lg btn-primary rounded" href="">
                                    <i className="bi bi-arrow-right fs-4"></i>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 wow zoomIn" data-wow-delay="0.9s">
                            <div className="position-relative bg-primary rounded h-100 d-flex flex-column align-items-center justify-content-center text-center p-3">
                                <h3 className="text-white mb-3">Bizə zəng edin</h3>
                                <p className="text-white mb-3"></p>
                                <a href={"tel:" + mobile?./*replaceAll('+', '').*/replaceAll(' ', '').replaceAll('-', '').replaceAll('/', '')} onClick={"ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"}>
                                    <h2 className="text-white mb-0">{mobile}</h2>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Service End --> */}
        </>
    )
}

export default Service