import React from 'react'
import mainData from '../data/mainData'
import { Link } from 'react-router-dom';
import LanguageSwitcher from './LanguageSwitcher';

function NavbarCarousel() {
    const {
        siteName,
        menu,
        slider,
    } = { ...mainData };
    
    return (
        <>
            {/* <!-- Navbar & Carousel Start --> */}
            <div className="container-fluid position-relative p-0">
                <nav className="navbar navbar-expand-lg navbar-dark px-5 py-3 py-lg-0">
                    <Link to="#top" className="navbar-brand p-0">
                        <h1 className="m-0"><i className="fa fa-user-tie me-2"></i>{siteName ?? ''}</h1>
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                        <span className="fa fa-bars"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarCollapse">
                        <div className="navbar-nav ms-auto py-0">
                            <a href="#top" className="nav-item nav-link active">{menu?.home ?? ''}</a>
                            <a href="#about" className="nav-item nav-link">{menu?.about ?? ''}</a>
                            <a href="#services" className="nav-item nav-link">{menu?.services ?? ''}</a>
                            {/* <div className="nav-item dropdown">
                                <a href="#top" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">{menu?.blog ?? ''}</a>
                                <div className="dropdown-menu m-0">
                                    <a href="#blog" className="dropdown-item">{menu?.blogGrid ?? ''}</a>
                                    <a href="#blog" className="dropdown-item">{menu?.blogDetail ?? ''}</a>
                                </div>
                            </div> */}
                            {/* <div className="nav-item dropdown">
                                <a href="#top" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">{menu?.pages ?? ''}</a>
                                <div className="dropdown-menu m-0">
                                    <a href="#price" className="dropdown-item">Pricing Plan</a>
                                    <a href="#features" className="dropdown-item">Our features</a>
                                    <a href="#team" className="dropdown-item">Team Members</a>
                                    <a href="#testimonial" className="dropdown-item">Testimonial</a>
                                    <a href="#quote" className="dropdown-item">Free Quote</a>
                                    <a href="#vendors" className="dropdown-item">Vendors</a>
                                </div>
                            </div> */}
                            <a href="#contacts" className="nav-item nav-link">{menu?.contact ?? ''}</a>
                            <span className="nav-item nav-link"><LanguageSwitcher /></span>
                        </div>
                        <butaton type="button" className="btn text-primary ms-3" data-bs-toggle="modal" data-bs-target="#searchModal"><i className="fa fa-search"></i></butaton>
                        {/* <a href="#top" className="btn btn-primary py-2 px-4 ms-3">{menu?.download ?? ''}</a> */}
                    </div>
                </nav>
                
                <div id="header-carousel" className="carousel slide carousel-fade" data-bs-ride="carousel">
                    <div className="carousel-inner">
                       <div className="carousel-item active">
                            <img className="w-100 img-cover" src={ require('../images/carousel-1.jpg') } alt="" />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{ maxWidth: '900px'}}> 
                                    <h5 className="text-white text-uppercase mb-3 animated slideInDown">{slider[0]?.second ?? ''}</h5>
                                    <h1 className="display-2 text-white mb-md-4 animated zoomIn">{slider[0]?.main ?? ''}</h1>
                                    <a href="#quote" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">{slider[0]?.quote ?? ''}</a>
                                    <a href="#quote" className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">{slider[0]?.contactUs ?? ''}</a>
                                </div>
                            </div>
                        </div>
                        <div className="carousel-item">
                            <img className="w-100 img-cover" src={ require('../images/business-brainstorming-graph-chart-report-data-concept-mini.jpg') } alt="" />
                            <div className="carousel-caption d-flex flex-column align-items-center justify-content-center">
                                <div className="p-3" style={{ maxWidth: '900px'}}>
                                    <h5 className="text-white text-uppercase mb-3 animated slideInDown">{slider[1]?.second ?? ''}</h5>
                                    <h1 className="display-2 text-white mb-md-4 animated zoomIn">{slider[1]?.main ?? ''}</h1>
                                    <a href="#quote" className="btn btn-primary py-md-3 px-md-5 me-3 animated slideInLeft">{slider[1]?.quote ?? ''}</a>
                                    <a href="#quote" className="btn btn-outline-light py-md-3 px-md-5 animated slideInRight">{slider[1]?.contactUs ?? ''}</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <button className="carousel-control-prev" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="prev">
                        <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Previous</span>
                    </button>
                    <button className="carousel-control-next" type="button" data-bs-target="#header-carousel"
                        data-bs-slide="next">
                        <span className="carousel-control-next-icon" aria-hidden="true"></span>
                        <span className="visually-hidden">Next</span>
                    </button>
                </div>
            </div>
            {/* <!-- Navbar & Carousel End --> */}
        </>
    )
}

export default NavbarCarousel