import React, { useEffect, useState } from 'react'

import mainData from '../data/mainData';

import { useDispatch, useSelector } from 'react-redux';
import { setLanguageAction } from '../redux/location/actions';
import { getLanguageSelector } from '../redux/location/selectors';

function LanguageSwitcher() {
  const dispatch = useDispatch();

  let [language, setLanguage] = useState('en');

  language = useSelector(getLanguageSelector);
  language = localStorage.getItem('lang') ?? 'en';

  useEffect(() => {
    mainData.staticLanguage = language;
  }, [language])

  const onLanguageClick = (e) => {
    setLanguage(e.target.dataset.lang);
    mainData.staticLanguage = e.target.dataset.lang;
    dispatch(setLanguageAction(e.target?.dataset?.lang ?? 'en'));
    localStorage.setItem('lang', e.target.dataset.lang);
    window.location.reload();
  }

  return (
    <>
      <div className='lang-menu'>
        <div className={'selected-lang ' + language}>

        </div>
        <ul>
          <li onClick={onLanguageClick}><span data-lang="en">English</span></li>
          <li onClick={onLanguageClick}><span data-lang="az">Azərbaycanca</span></li>
          <li onClick={onLanguageClick}><span data-lang="ru">Русский</span></li>
        </ul>
      </div>
    </>
  )
}

export default LanguageSwitcher