import React, { useState } from 'react'
import mainData from '../data/mainData'

function Search() {
    const {
        menu,
    } = { ...mainData };
	
    const [searchedText, setSearchedText] = useState('');

    const onSearchClick = (e) => {
        e.preventDefault();
        //alert(searchedText);
    }

    return (
        <>
            {/* <!-- Full Screen Search Start --> */}
            <div className="modal fade fadeIn" id="searchModal" tabIndex="-1">
                <div className="modal-dialog modal-fullscreen">
                    <div className="modal-content" style={{ background: 'rgba(9, 30, 62, .7)' }}>
                        <div className="modal-header border-0">
                            <button type="button" className="btn bg-white btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body d-flex align-items-center justify-content-center">
                            <div className="input-group" style={{ maxWidth: '600px' }}>
                                <input type="text" className="form-control bg-transparent border-primary p-3" placeholder={menu?.searchPlaceholder ?? ''} onChange={e => setSearchedText(e.target.value)} value={searchedText} />
                                <button className="btn btn-primary px-4" onClick={onSearchClick}><i className="bi bi-search fs-5"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Full Screen Search End --> */}
        </>
    )
}

export default Search