import React from 'react';
import mainData from '../data/mainData';

function Topbar() {
    const {
        mobile,
        address,
        email,
        whatsappLink,
        telegramLink,
        twitterLink,
        facebookLink,
        linkedinLink,
        instagramLink,
        youtubeLink
    } = { ...mainData };

    return (
        <>
            {/* <!-- Topbar Start --> */}
            <div id='top' className="container-fluid bg-dark px-5 d-none d-lg-block" style={{ backgroundColor: 'black' }}>
                <div className="row gx-0">
                    <div className="col-lg-8 text-center text-lg-start mb-2 mb-lg-0">
                        <div className="d-inline-flex align-items-center" style={{ height: 45 + 'px' }}>
                            <small className="me-3 text-light"><i className="fa fa-map-marker-alt me-2"></i>{address}</small>
                            <a href={"tel:" + mobile?./*replaceAll('+', '').*/replaceAll(' ', '').replaceAll('-', '').replaceAll('/', '')} onClick={"ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"}>
                                <small className="me-3 text-light"><i className="fa fa-phone-alt me-2"></i>{mobile ?? ''}</small>
                            </a>
                            <a style={{ textDecoration: 'none' }} href={"mailto:" + email ?? ''}>
                                <small className="text-light"><i className="fa fa-envelope-open me-2"></i>{email ?? ''}</small>
                            </a>
                        </div>
                    </div>
                    <div className="col-lg-4 text-center text-lg-end">
                        <div className="d-inline-flex align-items-center" style={{ height: 45 + 'px' }}>
                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" rel="noreferrer" href={whatsappLink}><i className="fab fa-whatsapp fw-normal"></i></a>
                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" rel="noreferrer" href={telegramLink}><i className="fab fa-telegram fw-normal"></i></a>
                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" rel="noreferrer" href={twitterLink}><i className="fab fa-twitter fw-normal"></i></a>
                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" rel="noreferrer" href={facebookLink}><i className="fab fa-facebook-f fw-normal"></i></a>
                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" rel="noreferrer" href={linkedinLink}><i className="fab fa-linkedin-in fw-normal"></i></a>
                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle me-2" target="_blank" rel="noreferrer" href={instagramLink}><i className="fab fa-instagram fw-normal"></i></a>
                            <a className="btn btn-sm btn-outline-light btn-sm-square rounded-circle" target="_blank" rel="noreferrer" href={youtubeLink}><i className="fab fa-youtube fw-normal"></i></a>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Topbar End --> */}
            {/* <span>Topbar</span> */}
        </>
    )
}

export default Topbar