import React, { useState } from 'react'
import mainData from '../data/mainData'

// Пример отправки POST запроса:
async function postData(url = '', data = {}) {
    // Default options are marked with *
    const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
        body: JSON.stringify(data) // body data type must match "Content-Type" header
    });
    return await response.json(); // parses JSON response into native JavaScript objects
}

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

function Footer() {
    const {
        mobile,
        address,
        email,
        siteName,
        whatsappLink,
        telegramLink,
        twitterLink,
        facebookLink,
        linkedinLink,
        instagramLink,
        youtubeLink,
        menu,
    } = { ...mainData };

    const styleSiteName = {
        overflowWrap: 'break-word',
        display: 'flex',
        flexDirection: 'column',
        fontSize: '1.8em',
        textAlign: 'center',
    }

    const [subscribeEmail, setSubscribeEmail] = useState('');
    const [responseMessage, setResponseMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [token, setToken] = useState('');
    const [linke, setLink] = useState('http://localhost:8083');

    const onSubscribeClick = (e) => {
        e.preventDefault();
        //alert(subscribeEmail);
        if (!validateEmail(subscribeEmail)) {
            alert("E-mail is not correct");
            return
        }
        //alert(subscribeEmail);

        //Начало
        const url = linke + '/subscribe'; // URL для отправки запроса
        //alert(url);
        const datas = {
            email: subscribeEmail,
            // Другие данные, которые вы хотите отправить
        };

        postData(url, datas)
            .then(response => {
                //alert(JSON.stringify(response)); // JSON data parsed by `response.json()` call
                setResponseMessage(JSON.stringify(response));
            })
            .catch(error => alert(error));

        if (JSON.parse(responseMessage)?.code == "ER_DUP_ENTRY") {
            alert("Artıq qeydə alınıb!");
        }
        if (JSON.parse(responseMessage)?.insertId > 0)
            alert("Qeydə alındı!");
        // const adda = JSON.stringify({ email: subscribeEmail });
        // alert(adda);

        // fetch(url, {
        //   method: 'POST',
        //   headers: { 'Content-Type': 'application/json', },
        //   body: adda,
        // })
        // .then(response => {
        //     if (response.ok) {
        //         return response.json(); // Получение JSON-ответа, если запрос успешен
        //     }
        //     return response.json();
        //     // else {
        //         // throw new Error('Ошибка при выполнении запроса');
        //     // }
        // })
        // .then(jsonResponse => {
        //     console.log(jsonResponse); // Обработка полученного JSON-ответа
        //     alert(jsonResponse);
        // })
        // .catch(error => {
        //     console.error(error); // Обработка ошибок запроса
        //     alert(error);
        // });
        //Конец


        // const requestOptions = {
        //     method: 'POST',
        //     //Authorization: 'Bearer ' + {token},
        //     //headers: { 'Content-Type': 'application/json' },
        //     // host: 'www.tradeintellect.pro.az',
        //     body: JSON.stringify({ email: subscribeEmail })
        // };
        // fetch({link}+'/subscribe', requestOptions)
        //     .then(response => response.json())
        //     .then(data => this.setResponseMessage(data))
        //     .catch(error => this.setErrorMessage(error))
        //     .finally(data => alert("Qeydə alındı!"));

        // alert(responseMessage);
        // alert(errorMessage);
    }

    return (
        <>
            {/* <!-- Footer Start --> */}
            <div id='footer' className="container-fluid bg-dark text-light mt-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container">
                    <div className="row gx-5">
                        <div className="col-lg-4 col-md-6 footer-about">
                            <div className="d-flex flex-column align-items-center justify-content-center text-center h-100 bg-primary p-4">
                                <a href="#top" className="navbar-brand" style={{ margin: '0' }}>
                                    <h1 className="m-0 text-white" style={styleSiteName}><i className="fa fa-user-tie me-2"></i>{siteName}</h1>
                                </a>
                                <p className="mt-3 mb-4">"Dijital dünyada uğurlu olun! Biz, <br/>'Trade Intellect' şirkəti kimi, sizi nailiyyətə çatdırmaq üçün ən innovativ proqram təminatı təklif edirik. İşinizin effektivliyini artırmağa kömək edən müasir və sərfəli proqramlarımız ilə vaxt və resursları qazanacaqsınız.</p>
                                <form>
                                    <div className="input-group">
                                        <input type="email" className="form-control border-white p-3" placeholder="Sizin elektron ünvanınız" onChange={e => setSubscribeEmail(e.target.value)} value={subscribeEmail} />
                                        <button onClick={onSubscribeClick} className="btn btn-dark">Abunə olmaq</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-lg-8 col-md-6">
                            <div className="row gx-5">
                                <div id='contacts' className="col-lg-4 col-md-12 pt-5 mb-5">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Əlagə saxlamaq</h3>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-geo-alt text-primary me-2"></i>
                                        <p className="mb-0">{address}</p>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-envelope-open text-primary me-2"></i>
                                        <a className='text-light' style={{ textDecoration: 'none' }} href={"mailto:" + email}>
                                            <p className="mb-0">{email}</p>
                                        </a>
                                    </div>
                                    <div className="d-flex mb-2">
                                        <i className="bi bi-telephone text-primary me-2"></i>
                                        <a className='text-light' href={"tel:" + mobile?./*replaceAll('+', '').*/replaceAll(' ', '').replaceAll('-', '').replaceAll('/', '')} onClick={"ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"}>
                                            <p className="mb-0">{mobile}</p>
                                        </a>
                                    </div>
                                    <div className="d-flex mt-4" style={{ display: 'flex', flexWrap: 'wrap' }}>
                                        <a className="btn btn-primary btn-square me-1 mt-1" target="_blank" rel="noreferrer" href={whatsappLink}><i className="fab fa-whatsapp fw-normal"></i></a>
                                        <a className="btn btn-primary btn-square me-1 mt-1" target="_blank" rel="noreferrer" href={telegramLink}><i className="fab fa-telegram fw-normal"></i></a>
                                        <a className="btn btn-primary btn-square me-1 mt-1" target="_blank" rel="noreferrer" href={twitterLink}><i className="fab fa-twitter fw-normal"></i></a>
                                        <a className="btn btn-primary btn-square me-1 mt-1" target="_blank" rel="noreferrer" href={facebookLink}><i className="fab fa-facebook-f fw-normal"></i></a>
                                        <a className="btn btn-primary btn-square me-1 mt-1" target="_blank" rel="noreferrer" href={linkedinLink}><i className="fab fa-linkedin-in fw-normal"></i></a>
                                        <a className="btn btn-primary btn-square me-1 mt-1" target="_blank" rel="noreferrer" href={instagramLink}><i className="fab fa-instagram fw-normal"></i></a>
                                        <a className="btn btn-primary btn-square me-1 mt-1" target="_blank" rel="noreferrer" href={youtubeLink}><i className="fab fa-youtube fw-normal"></i></a>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Sürətli keçidlər</h3>
                                    </div>
                                    <div className="link-animated d-flex flex-column justify-content-start">
                                        <a className="text-light mb-2" href="#top"><i className="bi bi-arrow-right text-primary me-2"></i>{menu?.home ?? ''}</a>
                                        <a className="text-light mb-2" href="#about"><i className="bi bi-arrow-right text-primary me-2"></i>Haqqımızda</a>
                                        <a className="text-light mb-2" href="#services"><i className="bi bi-arrow-right text-primary me-2"></i>{menu?.ourServices ?? ''}</a>
                                        <a className="text-light mb-2" href="#team"><i className="bi bi-arrow-right text-primary me-2"></i>Komandamız</a>
                                        {/* <a className="text-light mb-2" href="#blog"><i className="bi bi-arrow-right text-primary me-2"></i>Latest Blog</a> */}
                                        <a className="text-light" href="#quote"><i className="bi bi-arrow-right text-primary me-2"></i>Əlagə saxlamaq</a>
                                    </div>
                                </div>
                                <div className="col-lg-4 col-md-12 pt-0 pt-lg-5 mb-5">
                                    <div className="section-title section-title-sm position-relative pb-3 mb-4">
                                        <h3 className="text-light mb-0">Populyar keçidlər</h3>
                                    </div>
                                    <div className="link-animated d-flex flex-column justify-content-start">
                                        <a className="text-light mb-2" href="#top"><i className="bi bi-arrow-right text-primary me-2"></i>Əsas səhifə</a>
                                        <a className="text-light mb-2" href="#about"><i className="bi bi-arrow-right text-primary me-2"></i>Haqqımızda</a>
                                        <a className="text-light mb-2" href="#services"><i className="bi bi-arrow-right text-primary me-2"></i>Xidmətlərimiz</a>
                                        <a className="text-light mb-2" href="#team"><i className="bi bi-arrow-right text-primary me-2"></i>Komandamız</a>
                                        {/* <a className="text-light mb-2" href="#blog"><i className="bi bi-arrow-right text-primary me-2"></i>Latest Blog</a> */}
                                        <a className="text-light" href="#quote"><i className="bi bi-arrow-right text-primary me-2"></i>Bizimlə əlagə</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid text-white" style={{ background: '#061429' }}>
                <div className="container text-center">
                    <div className="row justify-content-end">
                        <div className="col-lg-8 col-md-6">
                            <div className="d-flex align-items-center justify-content-center" style={{ height: '75px' }}>
                                <p className="mb-0">&copy; <a className="text-white border-bottom" href="#top">{siteName}</a>. All Rights Reserved.

                                    {/* This template is free as long as you keep the footer author’s credit link/attribution link/backlink. If you'd like to use the template without the footer author’s credit link/attribution link/backlink, you can purchase the Credit Removal License from "https://htmlcodex.com/credit-removal". Thank you for your support. */}
                                    Designed by <a className="text-white border-bottom" target="_blank" rel="noreferrer" href="https://htmlcodex.com">HTML Codex</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- Footer End --> */}
        </>
    )
}

export default Footer