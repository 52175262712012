import React, { useState, useEffect } from 'react'
import mainData from '../data/mainData'

function Team() {
    const {
        teams_data,
        teams,
    } = { ...mainData };

    return (
        <>
            {/* <!-- Team Start --> */}
            <div id='team' className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="section-title text-center position-relative pb-3 mb-5 mx-auto" style={{ maxWidth: '600px' }} >
                        <h5 className="fw-bold text-primary text-uppercase">{teams_data?.members ?? ''}</h5>
                        <h1 className="mb-0">{teams_data?.description ?? ''}</h1>
                    </div>
                    <div className="row g-5">
                        {
                            teams?.length > 0 ?
                                (
                                    teams?.map((item, index) => (
                                        <div key={index} className="col-lg-4 wow slideInUp" data-wow-delay="0.3s">
                                            <div className="team-item bg-light rounded overflow-hidden">
                                                <div className="team-img position-relative overflow-hidden">
                                                    <img className="img-fluid w-100" src={require('../images/nobody.jpg')} alt="" />
                                                    <div className="team-social d-flex" style={{display: 'flex', flexWrap: 'wrap'}}>
                                                        <a className="btn btn-lg btn-primary btn-lg-square rounded" href={item?.whatsapp ?? ''}><i className="fab fa-whatsapp fw-normal"></i></a>
                                                        <a className="btn btn-lg btn-primary btn-lg-square rounded" href={item?.telegram ?? ''}><i className="fab fa-telegram fw-normal"></i></a>
                                                        {/* <a className="btn btn-lg btn-primary btn-lg-square rounded" href={item?.twitter ?? ''}><i className="fab fa-twitter fw-normal"></i></a> */}
                                                        <a className="btn btn-lg btn-primary btn-lg-square rounded" href={item?.facebook ?? ''}><i className="fab fa-facebook-f fw-normal"></i></a>
                                                        <a className="btn btn-lg btn-primary btn-lg-square rounded" href={item?.instagram ?? ''}><i className="fab fa-instagram fw-normal"></i></a>
                                                        {/* <a className="btn btn-lg btn-primary btn-lg-square rounded" href={item?.linkedin ?? ''}><i className="fab fa-linkedin-in fw-normal"></i></a> */}
                                                    </div>
                                                </div>
                                                <div className="text-center py-4">
                                                    <h4 className="text-primary">{item?.fullName ?? ''}</h4>
                                                    <p className="text-uppercase m-0">{item?.position ?? ''}</p>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                                ) : ''
                        }
                        {/*
                        <div className="col-lg-4 wow slideInUp" data-wow-delay="0.6s">
                            <div className="team-item bg-light rounded overflow-hidden">
                                <div className="team-img position-relative overflow-hidden">
                                    <img className="img-fluid w-100" src={require('../images/nobody.jpg')} alt="" />
                                    <div className="team-social">
                                        <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-twitter fw-normal"></i></a>
                                        <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-facebook-f fw-normal"></i></a>
                                        <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-instagram fw-normal"></i></a>
                                        <a className="btn btn-lg btn-primary btn-lg-square rounded" href=""><i className="fab fa-linkedin-in fw-normal"></i></a>
                                    </div>
                                </div>
                                <div className="text-center py-4">
                                    <h4 className="text-primary">{teams[1]?.fullName ?? ''}</h4>
                                    <p className="text-uppercase m-0">{teams[1]?.position ?? ''}</p>
                                </div>
                            </div>
                        </div>
                        */}
                    </div>
                </div>
            </div>
            {/* <!-- Team End --> */}
        </>
    )
}

export default Team