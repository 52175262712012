import React from 'react'
import mainData from '../data/mainData.js'

function About() {
    const {
        mobile
    } = { ...mainData };

    return (
        <>
            {/* <!-- About Start --> */}
            <div id='about' className="container-fluid py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-7">
                            <div className="section-title position-relative pb-3 mb-5">
                                <h5 className="fw-bold text-primary text-uppercase">Haqqımızda</h5>
                                <h1 className="mb-0">3 İllik təcrübə ilə ən yaxşı İT həlli</h1>
                            </div>
                            <p style={{marginBottom: '0.7em'}}>"Dijital dünyada uğurlu olun! Biz, 'Trade Intellect' şirkəti kimi, sizi nailiyyətə çatdırmaq üçün ən innovativ proqram təminatı təklif edirik. İşinizin effektivliyini artırmağa kömək edən müasir və sərfəli proqramlarımız ilə vaxt və resursları qazanacaqsınız. Bizi seçin və:</p>
                            <p style={{marginBottom: '0.2em'}}>✔ Xüsusi istəklər üçün adaptiv və təkmil həllərə nail olun.</p>
                            <p style={{marginBottom: '0.2em'}}>✔ Təhlil və statistika ilə mövqeyinizi qiymətləndirin.</p>
                            <p>✔ Müştərilərinizin tələblərinə uyğun, istifadəsi asan və rahat təminatı əldə edin.</p>
                            <div className="row g-0 mb-3">
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.2s">
                                    <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Biznesinizə dəstək</h5>
                                    <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Peşəkar heyət</h5>
                                </div>
                                <div className="col-sm-6 wow zoomIn" data-wow-delay="0.4s">
                                    <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>24/7 dəstək</h5>
                                    <h5 className="mb-3"><i className="fa fa-check text-primary me-3"></i>Münasib qiymətlər</h5>
                                </div>
                            </div>
                            <div className="d-flex align-items-center mb-4 wow fadeIn" data-wow-delay="0.6s">
                                <div className="bg-primary d-flex align-items-center justify-content-center rounded" style={{ width: '60px', height: '60px' }}>
                                    <i className="fa fa-phone-alt text-white fs-5"></i>
                                </div>
                                <div className="ps-4">
                                    <h5 className="mb-2">İstənilən sual üçün zəng edin</h5>
                                    <a href={"tel:" + mobile?./*replaceAll('+', '').*/replaceAll(' ', '').replaceAll('-', '').replaceAll('/', '')} onClick={"ga('send', 'event', { eventCategory: 'Contact', eventAction: 'Call', eventLabel: 'Mobile Button'});"}>
                                        <h4 className="text-primary mb-0">{mobile ?? ''}</h4>
                                    </a>
                                </div>
                            </div>
                            <a href="#quote" className="btn btn-primary py-3 px-5 mt-3 wow zoomIn" data-wow-delay="0.9s">Müraciət etmək</a>
                        </div>
                        <div className="col-lg-5" style={{ minHeight: '500px' }}>
                            <div className="position-relative h-100">
                                <img className="position-absolute w-100 h-100 rounded wow zoomIn" data-wow-delay="0.9s" src={require('../images/about.jpg')} style={{ objectFit: 'cover' }} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <!-- About End --> */}
        </>
    )
}

export default About